<template>
    <div>
         <b-container>
            <section class="advertise">
                <h2 class="main-title mb-4">Advertise</h2>
                <p class="custom-text">Online advertising is a powerful tool in the online marketing world. Display ads like banners are important factors if you want to advertise successfully on the internet. Advertising through graphic advertising media is usually known as display marketing. As part of our service we offer online advertising to raise awareness and drive leads from our audience. Right now we offer two advertising options.</p>
                <h4 class="custom-subtitle">Advertisements on our website</h4>
                <p class="custom-text">As part of banner advertising, we use predefined advertising space on websites to advertise graphics for specific brands or products. We offer two types of banners: <strong>homepage banner(1110x170)</strong> and <strong>aside banner(310x250)</strong>.</p>
                <h4 class="custom-subtitle">Homepage banner preview</h4>
                <div class="advertise__main-banner"></div>
                <h4 class="custom-subtitle">Aside banner preview</h4>
                <div class="advertise__aside-banner"></div>
                <h4 class="custom-subtitle">Contact</h4>
                <p class="custom-text">For more information, please contact us directly via email: <a href="mailto:contactbrandingpavilion@gmail.com" class="form-link form-link--medium">contactbrandingpavilion@gmail.com</a></p>
            </section>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>
